import * as ko from "knockout";
import BoundingBox from "./boundingBox";
import Point from "./point";

export default class Location {
    public title: ko.Computed<string>;
    public select: (data: Location) => void;
    public hasFocus: ko.Observable<boolean> = ko.observable(false);

    constructor(
        public readonly name1: string,
        public readonly name2: string,
        public readonly townCity: string,
        public readonly county: string,
        public readonly point: Point,
        public readonly boundingBox: BoundingBox) {
        this.title = ko.computed(() => {
            let title: string = "";
            if (this.name1) {
                title += this.name1;
            }
            if (this.name2) {
                if (title) {
                    title += ", ";
                }
                title += this.name2
            }
            if (this.townCity) {
                if (title) {
                    title += ", ";
                }
                title += this.townCity
            }
            if (this.county) {
                if (title) {
                    title += ", ";
                }
                title += this.county
            }
            return title;
        });
    }
}