import * as ko from "knockout";
import proj4 from '../proj4Init';

import OsNamesSearchProvider from "./osNamesSearchProvider";
import Location from "./location";
import Point from "./point";
import BoundingBox from "./boundingBox";
import OsApiConfigurationResponse from "../webHostClient/configuration/osApiConfigurationResponse";

export default class LocationSearcher {
    // Are we loading?
    public readonly loading: ko.Observable<boolean> = ko.observable<boolean>(true);

    // Search text (Debounced)
    public readonly searchText: ko.Observable<string> = ko.observable<string>('')
        .extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

    private _searchTextChangedSubscription: ko.Subscription;

    // Search Results
    public readonly locationSearchResults: ko.ObservableArray<Location> = ko.observableArray<Location>([]);

    // Should we show the search results drop down?
    public readonly showSearchResults: ko.Computed<boolean>;

    // Selected result
    public readonly selectedLocation: ko.Observable<Location> = ko.observable<Location>(null);

    public readonly settings: ko.Observable<OsApiConfigurationResponse> = ko.observable<OsApiConfigurationResponse>(null);

    // Can we start browser based geolocation?
    public readonly canStartGeolocate: ko.Computed<boolean> = ko.computed<boolean>(() => ("geolocation" in navigator));

    // Track which part of the control has focus
    public readonly searchTextHasFocus: ko.Observable<boolean> = ko.observable<boolean>(false);
    public readonly suggestionsHasFocus: ko.Observable<boolean> = ko.observable<boolean>(false);
    public readonly partOfWidgetHasFocus: ko.Computed<boolean>;

    // 500km squares from the south west corner starting at the origin (S, T, N, O and H
    private readonly _osGrid500 = [
        "S", "T",
        "N", "O",
        "H"
    ];

    // 100km squares from the south west corner
    private readonly _osGrid100 = [
        "V", "W", "X", "Y", "Z",
        "Q", "R", "S", "T", "U",
        "L", "M", "N", "O", "P",
        "F", "G", "H", "J", "K",
        "A", "B", "C", "D", "E"
    ]

    constructor() {
        // Wrap up the check that part of the widget has focus in an observable so we can debounce to avoid the
        // 'not item selected' state between one element losing focus and another gaining focus.
        this.partOfWidgetHasFocus = ko.computed(this._computePartOfWidgetHasFocus, this).extend({
            rateLimit: { timeout: 10, method: "notifyWhenChangesStop" }
        });
        this.showSearchResults = ko.computed(this._computeShowSearchResults, this);
        this._searchTextChangedSubscription = this.searchText.subscribe(this._searchTextChanged, this);
    }

    public selectLocation(location: Location): void {
        this.selectedLocation(location);

        this._searchTextChangedSubscription.dispose();
        this.searchText(location.title());
        this._searchTextChangedSubscription = this.searchText.subscribe(this._searchTextChanged, this);
    }

    public handleKeyUp(data: any, event: KeyboardEvent): boolean {
        let allowDefault = true;
        if ("Down" === event.key) {
            console.log("DOWN");
            this._moveSearchResultFocus(+1);
            allowDefault = false;
        }
        else if ("Up" === event.key) {
            console.log("UP");
            this._moveSearchResultFocus(-1);
            allowDefault = false;
        }
        else if ("Enter" === event.key) {
            console.log("ENTER");
            this.locationSearchResults().forEach((v, i) => {
                if (v.hasFocus()) {
                    this.selectLocation(v);
                }
            });
            allowDefault = false;
        }
        return allowDefault;
    }

    public handleKeyPress(data: any, event: KeyboardEvent): boolean {
        let allowDefault = true;
        if ("Down" === event.key || "Up" === event.key || "Enter" === event.key) {
            allowDefault = false;
        }
        return allowDefault;
    }

    public handleKeyDown(data: any, event: KeyboardEvent): boolean {
        let allowDefault = true;
        if ("Down" === event.key || "Up" === event.key || "Enter" === event.key) {
            allowDefault = false;
        }
        return allowDefault;
    }

    public startGeolocate(success: (location: Location) => void, error: () => void) {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => this._onGeolocateSuccess(position, success, error), error
            );
        }
    }

    private _computePartOfWidgetHasFocus(): boolean {
        let partOfWidgetHasFocus: boolean = (
            this.searchTextHasFocus() ||
            this.suggestionsHasFocus() ||
            this.locationSearchResults().some(l => l.hasFocus())
        );
        return partOfWidgetHasFocus;
    }

    private _computeShowSearchResults(): boolean {
        let showSearchResults = this.partOfWidgetHasFocus()
            && null === this.selectedLocation()
            && !!this.searchText();
        return showSearchResults;
    }

    private _moveSearchResultFocus(n: number) {
        let index: number = -1;

        if (this.locationSearchResults().length > 0) {
            this.locationSearchResults().forEach((v, i) => { if (v.hasFocus()) { index = i } });

            if (-1 != index) {
                // Got the index from the focussed item so we can move the selected item.
                index += n;
            }
            else {
                // No item has focus so lets focus the selected item - no need to move the selected item
                this.locationSearchResults().forEach((v, i) => { if (v == this.selectedLocation()) { index = i } });
                if (-1 != index) {
                    // No item is selected so give focus to the first item
                    index = 0;
                }
            }
            console.log("focus " + index);

            index = Math.max(0, index);
            index = Math.min(this.locationSearchResults().length - 1, index);
            console.log("focus (after bounds check) " + index);

            this.locationSearchResults()[index].hasFocus(true);
            console.log("focus2 " + index);
        }
    }

    private _searchTextChanged(newValue: string): void {
        let eastings: number;
        let northings: number;
        let digits: string;
        let gridLocation: Location;
        let boundingBoxOriginEastings: number;
        let boundingBoxOriginNorthings: number;
        let boundingBoxSize: number = 0.5;
        let locationName: string;

        this.selectedLocation(null);

        if (!!newValue) {
            this.loading(true);
            this.locationSearchResults.removeAll();

            if (this.settings()) {
                let searchProviver = new OsNamesSearchProvider(this.settings().namesMaxResults, this.settings().appKey);
                let handleSuccess = (locations: Array<Location>) => this._handleSearchSuccess(locations);

                // All numeric grid reference
                let matchAllNumeric: RegExpMatchArray = newValue.match(/^(?:Grid\sReference:\s?)?(\d{6})\s*,\s*(\d{6})$/);
                let matchLetterNumeric: RegExpMatchArray = newValue.match(/^(?:Grid\sReference:\s?)?(S|T|N|O|H)([A-HJ-Z])(\d{2}|\d{4}|\d{6}|\d{8}|\d{10})$/);
                if (matchAllNumeric || matchLetterNumeric) {
                    if (matchAllNumeric) {
                        eastings = parseInt(matchAllNumeric[1]);
                        northings = parseInt(matchAllNumeric[2]);
                        boundingBoxSize = 1;
                        locationName = "Grid Reference: " + matchAllNumeric[1] + ", " + matchAllNumeric[2];
                    }
                    else {
                        digits = matchLetterNumeric[3];

                        // Parse first letter - 500km squares S, T, N, O and H
                        // Division and modulo by 2 because we are only interested in the grid east and north
                        // of the grid origin with landmass so we only need the 2x3 grid
                        eastings = (this._osGrid500.indexOf(matchLetterNumeric[1]) % 2) * 500000;
                        northings = Math.floor(this._osGrid500.indexOf(matchLetterNumeric[1]) / 2) * 500000;

                        // Parse the second letter - 100km squares A-Z (NOTE: I is not used)
                        // Division and modulo by 5 because we need the entire 5x5 grid.
                        eastings += (this._osGrid100.indexOf(matchLetterNumeric[2]) % 5) * 100000;
                        northings += Math.floor(this._osGrid100.indexOf(matchLetterNumeric[2]) / 5) * 100000;

                        // Parse the numeric component - shift the decimal place depending on the number of digits we have been given
                        if (2 === digits.length) {
                            eastings += parseInt(digits.substring(0, 1)) * 10000;
                            northings += parseInt(digits.substring(1)) * 10000;
                            boundingBoxSize = 10000
                        }
                        else if (4 == digits.length) {
                            eastings += parseInt(digits.substring(0, 2)) * 1000;
                            northings += parseInt(digits.substring(2)) * 1000;
                            boundingBoxSize = 1000
                        }
                        else if (6 == digits.length) {
                            eastings += parseInt(digits.substring(0, 3)) * 100;
                            northings += parseInt(digits.substring(3)) * 100;
                            boundingBoxSize = 100
                        }
                        else if (8 == digits.length) {
                            eastings += parseInt(digits.substring(0, 4)) * 10;
                            northings += parseInt(digits.substring(4)) * 10;
                            boundingBoxSize = 10
                        }
                        else if (10 == digits.length) {
                            boundingBoxSize = 1
                            eastings += parseInt(digits.substring(0, 5));
                            northings += parseInt(digits.substring(5));
                        }

                        locationName = "Grid Reference: " + matchLetterNumeric[1] + matchLetterNumeric[2] + matchLetterNumeric[3];
                    }

                    // The coordinates we have are for the south west corner of the bounding box.
                    boundingBoxOriginEastings = eastings;
                    boundingBoxOriginNorthings = northings;
                    eastings = eastings + (boundingBoxSize / 2);
                    northings = northings + (boundingBoxSize / 2);

                    const latLongCoords: Array<number> = proj4('EPSG:27700', 'WGS84', [eastings, northings]);
                    const latLongMinCoords: Array<number> = proj4('EPSG:27700', 'WGS84', [eastings - boundingBoxSize, northings - boundingBoxSize]);
                    const latLongMaxCoords: Array<number> = proj4('EPSG:27700', 'WGS84', [eastings + boundingBoxSize, northings + boundingBoxSize]);

                    gridLocation = new Location(locationName, "", "", "",
                        new Point(latLongCoords[0], latLongCoords[1]),
                        new BoundingBox(
                            latLongMinCoords[0],
                            latLongMinCoords[1],
                            latLongMaxCoords[0],
                            latLongMaxCoords[1]));
                    gridLocation.select = (d) => this.selectLocation(d);
                    this.locationSearchResults.push(gridLocation);
                    searchProviver.searchOsgb36GridRef(northings, eastings, handleSuccess, () => { });
                }
                else {
                    searchProviver.searchText(newValue, handleSuccess);
                }
            }
        }
    }

    private _handleSearchSuccess(locations: Array<Location>): void {
        locations.forEach((l) => {
            l.select = (d) => this.selectLocation(d);
            this.locationSearchResults.push(l);
        });
        this.loading(false);
    }

    private _onGeolocateSuccess(position: GeolocationPosition, success: (location: Location) => void, error: () => void) {
        
        const point: Point = new Point(position.coords.longitude, position.coords.latitude);

        const bounds = new BoundingBox(
                position.coords.longitude - (position.coords.accuracy / (111111 * Math.cos(position.coords.latitude))),
                position.coords.latitude - (position.coords.accuracy / 111111),
                position.coords.longitude + (position.coords.accuracy / (111111 * Math.cos(position.coords.latitude))),
                position.coords.latitude + (position.coords.accuracy / 111111));
        
        const revGeoCodeFail = () => {
            success(new Location("", "", "", "", point, bounds));
        };

        const revGeoCodeSuccess = (locations: Array<Location>) => {
            if (locations.length > 0) {
                success(locations[0]);
            }
            else {
                revGeoCodeFail();
            }
        };

        // Don't even try the reverse geocode if we didn't get better than 100m accuracy
        if (position.coords.accuracy < 100) {

            // Convert lat/long to OSGB36 (British National Grid)
            const osgb36Coords: Array<number> = proj4('WGS84', 'EPSG:27700', [position.coords.longitude, position.coords.latitude]);

            let provider: OsNamesSearchProvider = new OsNamesSearchProvider(this.settings().namesMaxResults, this.settings().appKey);
            provider.searchOsgb36GridRef(osgb36Coords[1], osgb36Coords[0], revGeoCodeSuccess, revGeoCodeFail);
        }
        else {
            revGeoCodeFail();
        }
    }
}









