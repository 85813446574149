export default class BoundingBox {
    constructor(public readonly lonMin: number,
        public readonly latMin: number,
        public readonly lonMax: number,
        public readonly latMax: number) { }

    public toString(): string {
        let asString: string = "POLYGON((" + 
            this.lonMin + " " + this.latMin + "," +
            this.lonMax + " " + this.latMin + "," +
            this.lonMax + " " + this.latMax + "," + 
            this.lonMin + " " + this.latMax + "," + 
            this.lonMin + " " + this.latMin + "))";
             
        return asString;
    }
}