//

import BoundingBox from "./boundingBox";
import Point from "./point";
import Location from "./location";
import proj4 from '../proj4Init';

// OS Names implementation
export default class OsNamesSearchProvider {
    private readonly _gridRefPattern: RegExp = /\s*(\d{6})\s*,\s*(\d{6})\s*/;

    //
    // Constructor
    public constructor(private _searchLimit: number, private _key: string) {
    }

    //
    // Search for locations that match passed string
    public searchText(query: string, success: (locations: Array<Location>) => void): void {
        let queryEncoded: string = encodeURIComponent(query);
        $.ajax({
            url: 'https://api.os.uk/search/names/v1/find?query=' + queryEncoded + '&key=' + this._key + '&maxresults=' + this._searchLimit,
            success: (data) => success(this._responseToLocationArray(<IOsNamesFindResponse>data)),
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => console.log(textStatus + " " + errorThrown)
        });
    }

    //
    // Search for locations that match the passed grid reference
    public searchOsgb36GridRef(northings: number, eastings: number, success: (locations: Array<Location>) => void, error: () => void): void {
        let pointEncoded: string = encodeURIComponent(eastings.toString() + ',' + northings.toString());
        $.ajax({
            url: 'https://api.os.uk/search/names/v1/nearest?point=' + pointEncoded + '&key=' + this._key + '&radius=1000',
            success: (data) => success(this._responseToLocationArray(<IOsNamesFindResponse>data)),
            error: error
        });
    }

    //
    // Convert a response from the API to a LocationSearchResult (Suggestion)
    private _responseToLocationArray(response: IOsNamesFindResponse): Array<Location> {
        let locations: Array<Location> = [];

        if (response.header.totalresults > 0) {
            response.results.forEach((value) => {
                let name1: string;
                let name2: string;
                let townCity: string;
                let county: string;
                let point: Point;
                let boundingBox: BoundingBox = null;

                // Only use a subset of fields to represent the location to the user.
                // Don't duplicate any parts and don't include blanks
                if (value.GAZETTEER_ENTRY.COUNTY_UNITARY) {
                    county = value.GAZETTEER_ENTRY.COUNTY_UNITARY;
                }
                else {
                    county = null;
                }

                if (value.GAZETTEER_ENTRY.POPULATED_PLACE && county != value.GAZETTEER_ENTRY.POPULATED_PLACE) {
                    townCity = value.GAZETTEER_ENTRY.POPULATED_PLACE;
                }
                else {
                    townCity = null;
                }

                if (value.GAZETTEER_ENTRY.NAME2 && -1 === [county, townCity].indexOf(value.GAZETTEER_ENTRY.NAME2)) {
                    name2 = value.GAZETTEER_ENTRY.NAME2;
                }
                else {
                    name2 = null;
                }

                if (value.GAZETTEER_ENTRY.NAME1 && -1 === [county, townCity, name2].indexOf(value.GAZETTEER_ENTRY.NAME1)) {
                    name1 = value.GAZETTEER_ENTRY.NAME1;
                }
                else {
                    name1 = null;
                }

                // If we got a bounding box, include it in the result
                if (value.GAZETTEER_ENTRY.MBR_XMIN
                    && value.GAZETTEER_ENTRY.MBR_YMIN
                    && value.GAZETTEER_ENTRY.MBR_XMAX
                    && value.GAZETTEER_ENTRY.MBR_YMAX) {

                    const latLongMin: Array<number> = proj4('EPSG:27700', 'WGS84', [value.GAZETTEER_ENTRY.MBR_XMIN, value.GAZETTEER_ENTRY.MBR_YMIN]);
                    const latLongMax: Array<number> = proj4('EPSG:27700', 'WGS84', [value.GAZETTEER_ENTRY.MBR_XMAX, value.GAZETTEER_ENTRY.MBR_YMAX]);

                    boundingBox = new BoundingBox(latLongMin[0], latLongMin[1], latLongMax[0], latLongMax[1]);
                }
                else {
                    // When we give the OS api a really specific search string (like a postcode) then it starts
                    // to return just a point and not a bounding box. To keep our output consistent lets
                    // create a small bounding box using the point as the centre.

                    const latLongMin: Array<number> = proj4('EPSG:27700', 'WGS84', [value.GAZETTEER_ENTRY.GEOMETRY_X - 0.5, value.GAZETTEER_ENTRY.GEOMETRY_Y - 0.5]);
                    const latLongMax: Array<number> = proj4('EPSG:27700', 'WGS84', [value.GAZETTEER_ENTRY.GEOMETRY_X + 0.5, value.GAZETTEER_ENTRY.GEOMETRY_Y + 0.5]);
                    boundingBox = new BoundingBox(latLongMin[0], latLongMin[1], latLongMax[0], latLongMax[1]);
                }

                const latLong: Array<number> = proj4('EPSG:27700', 'WGS84', [value.GAZETTEER_ENTRY.GEOMETRY_X, value.GAZETTEER_ENTRY.GEOMETRY_Y]);
                point = new Point(latLong[0], latLong[1]);

                locations.push(new Location(name1, name2, townCity, county, point, boundingBox));
            });
        }
        return locations;
    }
}