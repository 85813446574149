// Represents a point (using lat long coordinates)
export default class Point {
    constructor(
        public readonly lon: number,
        public readonly lat: number) {
    }

    public toString(): string {
        const asString = "POINT(" + this.lon.toString() + " " + this.lat.toString() + ")";
        return asString;
    }
}